import { Col, Modal, Row } from "antd";
import * as React from "react";
import { confirmable } from "react-confirm";
import "./ChooseTypeDialog.css";
import Logo from "assets/images/logo-large.png";
import ButtonWithIcon from "components/ButtonWidgets/ButtonWithIcon/ButtonWithIcon";
import CommonButton from "components/ButtonWidgets/CommonButton/CommonButton";
function ChooseTypeDialog(props) {
  const { show, proceed } = props;
  const { title, appInfo, curDomain } = props;
  return (
    <Modal
      visible={show}
      destroyOnClose={true}
      onCancel={() => {
        proceed(null);
      }}
      className="alert-type-modal"
      closable={true}
      maskClosable={false}
      title={title}
      footer={null}
    >
      <div className="rec-alert-title">
        <img src={Logo} alt="Beclutch" className="img-logo" />
      </div>
      <div className="rec-alert-content">
        <Row align={"middle"} justify={"center"} gutter={[32, 32]}>
          <Col md={24} lg={12}>
            <CommonButton
              isDiv={true}
              style={{ padding: "12px 20px" }}
              onClick={() => {
                proceed(true);
              }}
            >
              New Participant Registration
            </CommonButton>
          </Col>
          {appInfo.group_list.length > 0 &&
            <Col md={24} lg={12}>
              <CommonButton
                isDiv={true}
                style={{ padding: "12px 45px" }}
                onClick={() => {
                  proceed(false);
                }}
              >
                Add Extra Programs
              </CommonButton>
            </Col>}
        </Row>
      </div>
    </Modal>
  );
}

export default confirmable(ChooseTypeDialog);
