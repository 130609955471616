import React, { useEffect, useState } from "react";
import { Checkbox, Col, Divider, Input, Row, message } from "antd";
import StepItemWidget from "./StepItemWidget";
import { capitalizeFirstLetter, getInitData } from "utils/GlobalFunctions";
import CommonButton from "components/ButtonWidgets/CommonButton/CommonButton";

const StepFee = (props) => {
  const { userData, setUserData, errorField, setErrorField, regType, curDomain } = props;

  useEffect(() => {
    // let savedUserInfo = getInitData();
    // setUserData({
    //   ...userData,
    //   ...savedUserInfo,
    // });
  }, []);

  const onCheck = (key, id) => {
    let tmpList = [...userData.camperInfo];
    let tmpTripList = [...userData.tripList];
    let tripKey = tmpTripList.findIndex((x) => x.id == id);
    let selTrip = tmpTripList[tripKey]

    for (let i = 0; i < tmpList.length; i++) {
      let itemInfo = tmpList[i];
      if (i == key) {
        if (itemInfo.sel_trip.includes(id)) {
          let tmpCheckedFees = [...itemInfo.sel_trip.split(",")];
          tmpCheckedFees = tmpCheckedFees.filter((x) => x != "" && x != id);
          let newIDs = tmpCheckedFees.join(",");
          if (newIDs != "") {
            newIDs = newIDs + ",";
          }
          itemInfo.sel_trip = newIDs;
          selTrip.current_registrations = parseInt(selTrip.current_registrations) - 1
        } else {
          if (parseInt(selTrip.max_registrations) === parseInt(selTrip.current_registrations)) {
            message.error("Sorry, no registrations remaining, please email the coordinator to be placed on a waiting list")
          } else {
            itemInfo.sel_trip = itemInfo.sel_trip + id + ",";
            selTrip.current_registrations = parseInt(selTrip.current_registrations) + 1
          }
        }
      }
      tmpList[i] = itemInfo;
    }
    tmpTripList[tripKey] = selTrip
    setUserData({
      ...userData,
      tripList: [...tmpTripList],
      camperInfo: [...tmpList],
    });
  };

  const onCheckReg = (e, val) => {
    let tmpList = [...userData.regList]
    tmpList = tmpList.map((x) => {
      if (x.ID == val.ID) {
        return { ...x, checked: e.target.checked }
      } else {
        if (e.target.checked) {
          return { ...x, checked: false }
        } else {
          return { ...x }
        }
      }
    })

    let selTmpList = [...tmpList]
    selTmpList = selTmpList.filter((x) => x.checked)
    let tmpOne = selTmpList.length > 0 ? selTmpList[0] : {
      registration_fee: 0,
      verify_zipcode: 0,
      zip_code_list: [],
      zip_discount_amount: 0,
      sibling_discount: 0,
      sibling_discount_amount: 0
    }

    let tmpCamperList = [...userData.camperInfo]
    for (let i = 0; i < tmpCamperList.length; i++) {
      let itemInfo = tmpCamperList[i];
      itemInfo.src_fee = parseFloat(tmpOne.registration_fee)
      if (itemInfo.camper_zip) {
        if (tmpOne.verify_zipcode == "1") {
          if (tmpOne.zip_code_list.includes(itemInfo.camper_zip?.toString()) || tmpOne.zip_code_list.includes(userData.camper_zip?.toString())) {
            itemInfo.zip_discount = parseFloat(tmpOne.zip_discount_amount)
            itemInfo.zip_include = true
          } else {
            itemInfo.zip_discount = 0
            itemInfo.zip_include = false
            message.error("Camper's Zip Code is not eligible for this Registration")
            return;
          }
        } else {

          if (tmpOne.zip_code_list.includes(itemInfo.camper_zip?.toString()) || tmpOne.zip_code_list.includes(userData.camper_zip?.toString())) {
            itemInfo.zip_discount = parseFloat(tmpOne.zip_discount_amount)
            itemInfo.zip_include = true
          } else {
            itemInfo.zip_discount = 0
            itemInfo.zip_include = false
          }
        }
      } else {
        itemInfo.zip_discount = 0
        itemInfo.zip_include = false
      }

      if (i == 0) {
        itemInfo.sibling_discount = 0
      } else {
        if (tmpOne.sibling_discount == "1") {
          itemInfo.sibling_discount = parseFloat(tmpOne.sibling_discount_amount)
        } else {
          itemInfo.sibling_discount = 0
        }
      }
      itemInfo.sibling_include = tmpOne.sibling_discount == "1"
      itemInfo.real_fee = itemInfo.src_fee - itemInfo.zip_discount - itemInfo.sibling_discount
      tmpCamperList[i] = itemInfo;
    }
    setUserData({ ...userData, camperInfo: [...tmpCamperList], camper_reg_checked: e.target.checked, camper_reg_sel_info: tmpOne, regList: tmpList });
  }
  return (
    <div className="profile-info-widget">
      <div>

        {regType == "true" && (
          <div>
            <span className="title">Participant Registrations</span>
            <Divider></Divider>
            <div className="block">
              <span className="title">Participant Registration</span>
              <Divider
                style={{ borderTop: "1px solid rgb(151, 196, 217, 71%)" }}
              ></Divider>
              {
                userData.regList.map((reg_one_info, i) => {
                  return <div key={reg_one_info.ID}>
                    <Row justify={"space-between"} style={{ marginTop: 20, fontSize: 15 }}>
                      <Col><Checkbox
                        onChange={(e) => {
                          onCheckReg(e, reg_one_info)
                        }}
                        checked={reg_one_info.checked}
                      >
                        <div>
                          {reg_one_info.registration_text.split("\\n").map((section, secKey) => {
                            return <div key={secKey}>{section}</div>;
                          })}
                        </div>
                      </Checkbox>
                      </Col>
                      <Col>${parseFloat(reg_one_info.registration_fee).toFixed(2)}</Col>
                    </Row>
                  </div>
                })
              }
            </div>
          </div>
        )}
        {userData.tripList.length > 0 &&
          <div style={{ marginTop: 20 }}>
            <span className="title">
              Extra Programs (can be added at a later date)
            </span>
            <Divider></Divider>
            {userData.camperInfo.map((camper, key) => {
              return (
                <div key={key}>
                  <div
                    style={{
                      fontSize: 16,
                      color: "var(--blueColor)",
                      marginTop: 10,
                    }}
                  >
                    Participant {key + 1} Extra Programs
                  </div>
                  <div className="block" style={{ marginTop: 10, padding: 10 }}>
                    {userData.tripList.map((x, index) => {
                      return (
                        <StepItemWidget
                          itemInfo={x}
                          onCheck={onCheck}
                          key={index}
                          camperIndex={key}
                          isLast={index == userData.tripList.length - 1}
                          userData={userData}
                        />
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>}
        {regType == "true" && (
          <>
            {userData.waiverList.map((waiver_info, index) => {
              return <div style={{ marginTop: 20 }} key={waiver_info.ID}>
                <div className="block">
                  <div
                    className="title"
                    style={{ textAlign: "center", paddingTop: 5 }}
                  >
                    {waiver_info.waiver_title}
                  </div>
                  <div style={{ marginTop: 10, fontWeight: 600 }}>
                    {waiver_info.waiver_text.split("\\n").map((section, secKey) => {
                      return <div key={secKey}>{section}</div>;
                    })}
                  </div>
                  {waiver_info.allow_no == "1" &&
                    <Checkbox
                      onChange={(e) => {
                        let tmpList = [...userData.waiverList]
                        tmpList[index].allow_no_checked = e.target.checked;
                        setUserData({
                          ...userData,
                          waiverList: tmpList,
                        });
                      }}
                      checked={waiver_info.allow_no_checked}
                    >
                      <div style={{ marginTop: 20, fontSize: 15 }}>
                        I DO NOT AGREE
                      </div>
                    </Checkbox>
                  }
                  <div
                    style={{
                      fontWeight: 600,
                      marginTop: 20,
                      marginBottom: 20,
                      color: "var(--blueColor)",
                    }}
                  >
                    I have read and understand the above <br></br>Electronic Signature
                  </div>
                  <Row gutter={[16, 16]} align={"bottom"}>
                    <Col xs={24} sm={10}>
                      <div style={{ paddingBottom: "5px" }}>
                        Enter First Name*
                      </div>
                      <Input
                        value={waiver_info.first_name}
                        disabled={waiver_info.allow_no_checked}
                        className={
                          errorField !== undefined &&
                            errorField.includes(index + "first_name")
                            ? `common-input-error`
                            : `common-input`
                        }
                        onChange={(e) => {
                          if (errorField.includes(index + "first_name")) {
                            let errors = errorField.filter(
                              (y) => y != index + "first_name"
                            );
                            setErrorField([...errors]);
                          }
                          let tmpList = [...userData.waiverList]
                          tmpList[index].first_name = capitalizeFirstLetter(
                            e.target.value
                          );
                          setUserData({
                            ...userData,
                            waiverList: tmpList,
                          });
                        }}
                      />
                    </Col>
                    <Col xs={24} sm={10}>
                      <div style={{ paddingBottom: "5px" }}>Enter Last Name*</div>
                      <Input
                        value={waiver_info.last_name}
                        disabled={waiver_info.allow_no_checked}
                        className={
                          errorField !== undefined &&
                            errorField.includes(index + "last_name")
                            ? `common-input-error`
                            : `common-input`
                        }
                        onChange={(e) => {
                          if (errorField.includes(index + "last_name")) {
                            let errors = errorField.filter(
                              (y) => y != index + "last_name"
                            );
                            setErrorField([...errors]);
                          }
                          let tmpList = [...userData.waiverList]
                          tmpList[index].last_name = capitalizeFirstLetter(
                            e.target.value
                          );
                          setUserData({
                            ...userData,
                            waiverList: tmpList,
                          });
                        }}
                      />
                    </Col>
                    <Col xs={24} sm={4} style={{ paddingBottom: 7 }}>
                      {!waiver_info.allow_no_checked &&
                        <CommonButton onClick={() => {

                          let tmpList = [...userData.waiverList]
                          tmpList[index].first_name = userData.p_first_name;
                          tmpList[index].last_name = userData.p_last_name;
                          setUserData({
                            ...userData,
                            waiverList: tmpList,
                          });

                        }}>
                          Sign
                        </CommonButton>}
                    </Col>
                  </Row>
                  <Checkbox
                    disabled={waiver_info.allow_no_checked}
                    onChange={(e) => {
                      let tmpList = [...userData.waiverList]
                      tmpList[index].checked = e.target.checked;
                      setUserData({
                        ...userData,
                        waiverList: tmpList,
                      });
                    }}
                    checked={waiver_info.checked}
                  >
                    <div style={{ marginTop: 20, fontSize: 15 }}>
                      I agree and understand that
                      all electronic signatures are the legal equivalent of my
                      manual/handwritten signature and I consent to be legally
                      bound to this agreement. I further agree my signature on
                      this page is as valid as if I signed the document in
                      writing.
                    </div>
                  </Checkbox>
                </div>
              </div>
            })}
          </>
        )}
      </div>
    </div>
  );
};

export default StepFee;
