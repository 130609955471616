import React, { useEffect, useState } from "react";
import { Row, Col, Input, Divider, Select, InputNumber, message } from "antd";
import "./StepOne.css";
import { CloseOutlined } from "@ant-design/icons";
import CommonButton from "components/ButtonWidgets/CommonButton/CommonButton";
import UiForm from "components/UiForm/UiForm";

const StepOne = (props) => {
  const {
    userData,
    setUserData,
    errorField,
    setErrorField,
    recordList,
    setRecordList,
    regType,
    curDomain,
    uiList
  } = props;
  const [mainFormList, setMainFormList] = useState([]);


  useEffect(() => {
    if (uiList.length > 0) {
      let tmpMList = [...uiList.filter((x) => x.group_type == "1")]
      setMainFormList(tmpMList)
    }
  }, [uiList])

  const onAddMore = () => {
    let tmpList = [...userData.camperInfo];
    let newInfo = {
      camper_first_name: "",
      camper_last_name: "",
      camper_street: tmpList[0].camper_street,
      camper_city: tmpList[0].camper_city,
      camper_state: tmpList[0].camper_state,
      camper_zip: tmpList[0].camper_zip,
      sel_trip: "",
    };
    tmpList = [...tmpList, newInfo];
    setUserData({
      ...userData,
      camperInfo: tmpList,
    });
    let tmpRecordList = [...recordList];
    setRecordList([...tmpRecordList, {}]);
  };
  const onDelPlayer = (index) => {
    let tmpList = [...userData.camperInfo];
    tmpList = tmpList.filter((x, k) => k !== index);

    setUserData({
      ...userData,
      camperInfo: tmpList,
    });
    let tmpRecordList = [...recordList];
    tmpRecordList = tmpRecordList.filter((x, k) => k !== index);
    setRecordList(tmpRecordList);
  };
  return (
    <div className="profile-info-widget">
      <div>
        <span className="title">Step 1: Enter Participant Info</span>
        <Divider></Divider>
      </div>
      <div className="block">
        {userData.camperInfo.map((x, index) => {
          return (
            <div key={index}>
              <div>
                <Row justify="space-between">
                  <Col>
                    <span className="title">{mainFormList.length > 0 ? mainFormList[0].group_title : "Participant Info"} {index + 1} </span>
                  </Col>
                  <Col>
                    <span
                      className="title"
                      onClick={() => {
                        onDelPlayer(index);
                      }}
                      style={
                        index == 0 ? { display: "none" } : { cursor: "pointer" }
                      }
                    >
                      Close
                    </span>
                  </Col>
                </Row>
                <Divider
                  style={{ borderTop: "1px solid rgb(151, 196, 217, 71%)" }}
                ></Divider>
              </div>
              <div style={{ marginTop: 20, marginBottom: 20 }}>
                <Row gutter={[16, 16]}>
                  {mainFormList.map((element, fIndex) => {
                    return <UiForm
                      key={fIndex}
                      index={index}
                      recordList={recordList}
                      setRecordList={setRecordList}
                      element={element}
                      errorField={errorField}
                      setErrorField={setErrorField}
                      userData={userData}
                      setUserData={setUserData}
                      data={x}
                    />
                  })}
                </Row>
              </div>
            </div>
          );
        })}
        <div style={{ marginTop: 40, textAlign: "center", marginBottom: 10 }}>
          <CommonButton
            customClass="register-next-button"
            onClick={() => {
              onAddMore();
            }}
          >
            Add Another Participant
          </CommonButton>
        </div>
      </div>
    </div>
  );
};

export default StepOne;
