import React, { useEffect, useState } from "react";
import { Row, Col, Input, Divider, Select, Switch } from "antd";
import "./StepTwo.css";
import { MaskedInput } from "antd-mask-input";
import InputMask from "react-input-mask";
import UiForm from "components/UiForm/UiForm";
import UiFormSingle from "components/UiForm/UiFormSingle";
const StepTwo = (props) => {
  const {
    userData,
    setUserData,
    errorField,
    setErrorField,
    homePhone,
    setHomePhone,
    cellPhone,
    setCellPhone,
    contactPhone,
    setContactPhone,
    acontactPhone,
    setAContactPhone,
    aCellPhone,
    aHomePhone,
    setAHomePhone,
    setACellPhone,
    regType,
    curDomain,
    uiList
  } = props;
  const [parentFormList, setParentFormList] = useState({});
  const [contactFormList, setContactFormList] = useState({});
  const [otherFormList, setOtherFormList] = useState({});
  useEffect(() => {
    if (uiList.length > 0) {
      let tmpPList = [...uiList.filter((x) => x.group_type == "2")]
      let newPList = tmpPList.reduce((acc, obj) => {
        const key = obj.group_title;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
      }, {});

      setParentFormList(newPList)
      let tmpCList = [...uiList.filter((x) => x.group_type == "3")]

      let newCList = tmpCList.reduce((acc, obj) => {
        const key = obj.group_title;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
      }, {});
      setContactFormList(newCList)

      let tmpOList = [...uiList.filter((x) => x.group_type == "4")]

      let newOList = tmpOList.reduce((acc, obj) => {
        const key = obj.group_title;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
      }, {});
      setOtherFormList(newOList)
    }
  }, [uiList])
  const { Option } = Select;
  return (
    <div className="profile-info-widget">
      {(Object.keys(contactFormList).length > 0 || Object.keys(parentFormList).length > 0) && <div>
        <span className="title">Step 2: Enter Parent/Guardian Info (As it appears on credit card)</span>
        <Divider></Divider>
      </div>}

      {Object.keys(parentFormList).map((pInfo, pKey) => {
        return <div className="block" key={pKey}>
          <div>
            <Row justify="space-between">
              <Col>
                <span className="title"> {pInfo}</span>
              </Col>
              <Col>
                <span
                  className="title"
                  style={{ cursor: "pointer", display: "none" }}
                >
                  Close
                </span>
              </Col>
            </Row>
            <Divider
              style={{ borderTop: "1px solid rgb(151, 196, 217, 71%)" }}
            ></Divider>
          </div>

          <div style={{ marginTop: 20 }}>
            <Row gutter={[16, 16]}>
              {parentFormList[pInfo].map((element, fIndex) => {
                return <UiFormSingle
                  key={fIndex}
                  index={''}
                  recordList={[]}
                  setRecordList={undefined}
                  element={element}
                  errorField={errorField}
                  setErrorField={setErrorField}
                  userData={userData}
                  setUserData={setUserData}
                  data={userData}
                />
              })}
            </Row>
          </div>
        </div>
      })}
      {Object.keys(contactFormList).length > 0 && Object.keys(contactFormList).map((cInfo, cKey) => {
        return <div className="block" style={{ marginTop: 40 }} key={cKey}>
          <div>
            <Row justify="space-between">
              <Col>
                <span className="title">{cInfo}</span>
              </Col>
              <Col>
                <span
                  className="title"
                  style={{ cursor: "pointer", display: "none" }}
                >
                  Close
                </span>
              </Col>
            </Row>
            <Divider
              style={{ borderTop: "1px solid rgb(151, 196, 217, 71%)" }}
            ></Divider>
          </div>
          <div style={{ marginTop: 20 }}>
            <Row gutter={[16, 16]}>
              {contactFormList[cInfo].map((element, fIndex) => {
                return <UiFormSingle
                  key={fIndex}
                  index={''}
                  recordList={[]}
                  setRecordList={undefined}
                  element={element}
                  errorField={errorField}
                  setErrorField={setErrorField}
                  userData={userData}
                  setUserData={setUserData}
                  data={userData}
                />
              })}
            </Row>
          </div>
        </div>
      })}
      {Object.keys(otherFormList).length > 0 && Object.keys(otherFormList).map((oInfo, cKey) => {
        return <div className="block" style={{ marginTop: 40 }} key={cKey}>
          <div>
            <Row justify="space-between">
              <Col>
                <span className="title">{oInfo}</span>
              </Col>
              <Col>
                <span
                  className="title"
                  style={{ cursor: "pointer", display: "none" }}
                >
                  Close
                </span>
              </Col>
            </Row>
            <Divider
              style={{ borderTop: "1px solid rgb(151, 196, 217, 71%)" }}
            ></Divider>
          </div>
          <div style={{ marginTop: 20 }}>
            <Row gutter={[16, 16]}>
              {otherFormList[oInfo].map((element, fIndex) => {
                return <UiFormSingle
                  key={fIndex}
                  index={''}
                  recordList={[]}
                  setRecordList={undefined}
                  element={element}
                  errorField={errorField}
                  setErrorField={setErrorField}
                  userData={userData}
                  setUserData={setUserData}
                  data={userData}
                />
              })}
            </Row>
          </div>
        </div>
      })}
    </div>
  );
};

export default StepTwo;
